import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'hiji-bottom-buttons',
  templateUrl: './bottom-buttons.component.html',
  styleUrls: ['./bottom-buttons.component.scss'],
})
export class BottomButtonsComponent implements OnInit {
  @Input()
  rootLink: string;
  @Input()
  endLink: string;
  @Input()
  itemLink: string;
  @Input()
  backLinkOnAdd: string;
  @Input()
  edit: boolean;
  @Input()
  add: boolean;
  @Input()
  addLoading: boolean;
  @Input()
  editLoading: boolean;
  @Input()
  deleteLoading: boolean;
  @Input() disableDelete: boolean = false;
  @Output()
  deleteClicked = new EventEmitter<void>();
  @Output()
  saveEditClicked = new EventEmitter<void>();
  @Output()
  addClicked = new EventEmitter<void>();
  constructor() {}

  ngOnInit(): void {}
}
