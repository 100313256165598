import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { HijiCommonModule, TRANSLATE_CHILD_CONFIG } from 'common';
import { BottomButtonsComponent } from './bottom-buttons/bottom-buttons.component';
import { TopButtonsComponent } from './top-buttons/top-buttons.component';
import { WavyBgComponent } from './wavy-bg.component';

@NgModule({
  declarations: [WavyBgComponent, TopButtonsComponent, BottomButtonsComponent],
  imports: [CommonModule, TranslateModule.forChild(TRANSLATE_CHILD_CONFIG), RouterModule, HijiCommonModule],
  exports: [WavyBgComponent, TopButtonsComponent, BottomButtonsComponent],
})
export class CommonWavyModule {}
