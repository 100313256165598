<div class="row mt-3 justify-content-end" *ngIf="edit">
  <div class="col-auto mt-2 mt-lg-0">
    <button
      class="btn btn-outline-medium"
      type="button"
      routerLink="/{{ rootLink }}/view/{{ itemLink }}{{ endLink }}"
      [disabled]="addLoading || editLoading || deleteLoading"
    >
      <i class="fa-light fa-undo-alt me-0 me-md-2"></i>
      <span class="d-none d-md-inline">{{ 'global.Annuler' | translate }}</span>
    </button>
  </div>
  <div class="col-auto mt-2 mt-lg-0" *ngIf="!disableDelete">
    <button class="btn btn-secondary" type="button" (click)="deleteClicked.emit()" [disabled]="deleteLoading">
      <i class="fa-light fa-trash me-0 me-md-2"></i>
      <span class="d-none d-md-inline">{{ 'global.Supprimer' | translate }}</span>
      <i class="fa-light fa-spinner spinning ms-2" *ngIf="deleteLoading"></i>
    </button>
  </div>
  <div class="col-auto mt-2 mt-lg-0">
    <button class="btn btn-primary" type="submit" (click)="saveEditClicked.emit()" [disabled]="editLoading">
      <i class="fa-light fa-save me-0 me-md-2"></i>
      <span class="d-none d-md-inline">{{ 'global.Valider' | translate }}</span>
      <i class="fa-light fa-spinner spinning ms-2" *ngIf="editLoading"></i>
    </button>
  </div>
</div>

<div class="row mt-3 justify-content-end" *ngIf="add">
  <div class="col-auto mt-2 mt-md-0">
    <button class="btn btn-outline-medium" type="button" [routerLink]="backLinkOnAdd === undefined ? ['/' + rootLink] : backLinkOnAdd" [disabled]="addLoading">
      <i class="fa-light fa-undo-alt me-0 me-md-2"></i>
      <span class="d-none d-md-inline">{{ 'global.Annuler' | translate }}</span>
    </button>
  </div>
  <div class="col-auto mt-2 mt-md-0">
    <button class="btn btn-primary" type="submit" (click)="addClicked.emit()" [disabled]="addLoading">
      <i class="fa-light fa-save me-0 me-md-2"></i>
      <span class="d-none d-md-inline">{{ 'global.Valider' | translate }}</span>
      <i class="fa-light fa-spinner spinning ms-2" *ngIf="addLoading"></i>
    </button>
  </div>
</div>
